<template>
    <div class="yz-contact-wap">
         <erji-carousel></erji-carousel>
        <div class="ab-text">
            <div class="ab-title animate__bounceIn" >
                 <p>—— 联系我们 ——</p>
            </div>
            <div class="ab-desc animate__bounceIn">
                 <p>
                     有任何疑问，可以联系我们！
                </p>
             </div>
        </div>
        <div class="addess">
            <p>北京地址：北京市海淀区学院南路12号-北京师范大学京师科技大厦A座13层</p>
            <p>石家庄地址：中国石家庄市桥西区建设南大街6号西美大厦写字楼9层917</p>
            <p>重庆地址：中国重庆市渝中区中山三路161号广发大厦5层</p>
            <p>苏州地址：中国苏州市吴中区月亮湾10号慧湖大厦B栋402</p>
            <p>青岛地址：中国青岛市市北区嘉定路5号青岛工业设计产业园A座301</p>
            <p>济南地址：中国济南市历城区旅游路4518号现代服务业综合实训基地414</p>
            <p>潍坊地址：中国潍坊市昌邑市院校中街329号</p>
            <p>营口地址：中国营口市鲅鱼圈区海星街道滨海大街华海国际大厦</p>
        </div>
    </div>
</template>
<script>
import ErjiCarousel from '@/components/erjiCarousel.vue'
export default {
   components: {
      ErjiCarousel
  },
  data(){
    return{

    }
  }
}
</script>
<style lang="less" scoped>
   .yz-contact-wap{
     .ab-text{
           margin-top:40px;
           .ab-title{
               text-align: center;
               font-size: 30px;
               color:#0184d2;
           }
           .ab-desc{
               margin-top:10px;
               text-align: center;
               font-size: 14px;
               color: #656d78;
           }
       }
       .addess{
           width: 1200px;
           margin:0 auto;
           padding:50px 0px 50px 0px;
           p{
               font-size: 16px;
               line-height: 40px;
           }
       }
   }
</style>
<style>
.animate__bounceIn {
  --animate-duration: 4s;
}
</style>
